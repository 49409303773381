import React from 'react';
import styled from 'styled-components';
import {Link, navigate} from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover, faCcDinersClub, faCcJcb, faCcPaypal } from '@fortawesome/free-brands-svg-icons'


const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <TopSection>
                <PromoText>The most advanced VPN.<br />And much more.</PromoText>
                <GetStartedButton onClick={() => { navigate('/login')}}>Get Started</GetStartedButton>
            </TopSection>
            <BottomSection>
                <LeftSection>
                    <Logo>DuckDuckLink</Logo>
                    <Copyright>© 2024 DuckDuckLink</Copyright>
                </LeftSection>
                <RightSection>
                    <NavLinks>
                        <NavItem to="/">Features</NavItem>
                        <NavItem to="/pricing">Pricing</NavItem>
                        <NavItem to="/contact">Contact US</NavItem>
                    </NavLinks>
                    {/*<SocialMedia>*/}
                    {/*    <i className="fab fa-linkedin-in" />*/}
                    {/*    <i className="fab fa-facebook-f" />*/}
                    {/*    <i className="fab fa-twitter" />*/}
                    {/*</SocialMedia>*/}
                    <PaymentMethods>
                        <FontAwesomeIcon icon={faCcVisa} />
                        <FontAwesomeIcon icon={faCcMastercard} />
                        <FontAwesomeIcon icon={faCcAmex} />
                        <FontAwesomeIcon icon={faCcDiscover} />
                        <FontAwesomeIcon icon={faCcDinersClub} />
                        <FontAwesomeIcon icon={faCcJcb} />
                        <FontAwesomeIcon icon={faCcPaypal} />
                    </PaymentMethods>
                </RightSection>
            </BottomSection>
        </FooterContainer>
    );
};

export default Footer;

const FooterContainer = styled.footer`
    background-color: #000;
    color: #fff;
    padding: 2rem 1rem;
    border-radius: 25px;
    margin: 2rem 1rem;
`;

const TopSection = styled.div`
    text-align: center;
    padding: 2rem 0;
`;

const PromoText = styled.h2`
    font-size: 2rem;
    color: #eff87a;
    margin-bottom: 1rem;
`;

const GetStartedButton = styled.button`
    background-color: #eff87a;
    color: #000;
    border: none;
    padding: 0.75rem 2rem;
    border-radius: 50px;
    font-weight: 700;
    cursor: pointer;
    font-size: 1.2rem;

    &:hover {
        background-color: #d8e253;
    }
`;

const BottomSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
`;

const Logo = styled.div`
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
`;

const Copyright = styled.div`
    color: #888;
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 1rem;
    }
`;

const NavLinks = styled.div`
    display: flex;
    gap: 1rem;
    margin-right: 2rem;

    @media (max-width: 768px) {
        margin-bottom: 1rem;
    }
`;

const NavItem = styled(Link)`
    color: #fff;
    text-decoration: none;
    font-size: 1rem;

    &:hover {
        color: #eff87a;
    }
`;

const SocialMedia = styled.div`
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
    margin-right: 2rem;

    i {
        color: #fff;
        cursor: pointer;

        &:hover {
            color: #eff87a;
        }
    }
`;

const PaymentMethods = styled.div`
    display: flex;
    gap: 0.5rem;

    i {
        font-size: 2rem;
        color: #fff;

        &:hover {
            color: #eff87a;
        }
    }
`;
